export const menuLinks = [
  {
    id: 1,
    title: 'Product',
    url: '/products',
  },
  {
    id: 2,
    title: 'Plans & Pricing',
    url: '/plans',
  },
  {
    id: 3,
    title: 'Company',
    url: '/company',
  },
  {
    id: 4,
    title: 'Support',
    url: '/support',
  },
];

export const footerLinks = [
  {
    id: 1,
    title: 'Product',
    url: '/products',
  },
  {
    id: 2,
    title: 'Pricing & Plan',
    url: '/plans',
  },
  {
    id: 3,
    title: 'Company',
    url: '/company',
  },
  {
    id: 4,
    title: 'Blog',
    url: 'https://blog.lasercards.co',
  },
  {
    id: 5,
    title: 'Help & FAQs',
    url: 'https://notion.so/8ca9fd4dd5e3491da03135760ac0551e',
  },
  {
    id: 6,
    title: 'Privacy Policy',
    url: 'https://lasercards.super.site/privacy',
  },
  {
    id: 7,
    title: 'Terms of Service',
    url: 'https://lasercards.super.site/terms',
  },
];

export const socialLinks = [
  // {
  //     id: 1,
  //     image: linkedin,
  //     title: 'linkedIn',
  //     url: '/',
  // },
  // {
  //     id: 2,
  //     image: facebook,
  //     title: 'facebook',
  //     url: '/',
  // },
  {
    id: 3,
    image: '/assets/instagram.svg',
    title: 'instagram',
    url: 'https://instagram.com/lasercardsco',
  },
  {
    id: 4,
    image: '/assets/twitter.svg',
    title: 'x',
    url: 'https://x.com/lasercardsco',
  },
];
export const useCase = [
  {
    id: 1,
    image: '/assets/ASOS.svg',
    title: 'asos',
  },
  {
    id: 2,
    image: '/assets/Google.svg',
    title: 'google',
  },
  {
    id: 3,
    image: '/assets/Netflix.svg',
    title: 'netflix',
  },
  {
    id: 4,
    image: '/assets/Spotify.svg',
    title: 'spotify',
  },
  {
    id: 5,
    image: '/assets/AliExpress.svg',
    title: 'ali-express',
  },
  {
    id: 6,
    image: '/assets/FacebookImage.svg',
    title: 'facebook',
  },
  {
    id: 7,
    image: '/assets/Apple.svg',
    title: 'apple',
  },
];

export const featuresCard = [
  {
    id: 1,
    image: '/assets/fee.svg',
    title: 'Instant Issuance',
    description: 'Get your virtual card details instantly upon approval and start using it immediately',
    backgroundColor: 'bg-[#FBF9FE]',
  },
  {
    id: 2,
    image: '/assets/lowestRate.svg',
    title: 'Low Transaction Rates',
    description: 'At LaserCards, we provide you with competitive transaction rates, helping you spend more at lower prices',
    backgroundColor: 'bg-[#FFF8F5]',
  },
  {
    id: 3,
    image: '/assets/fast.svg',
    title: 'Fast And Easy',
    description: 'No long waits, and no complicated forms. Experience quick and easy account verification and start shopping',
    backgroundColor: 'bg-[#F1F9F4]',
  },
  {
    id: 4,
    image: 'assets/inone.svg',
    title: 'Subscriptions',
    description: 'Manage your subscriptions automatically without exceeding your spending limit',
    backgroundColor: 'bg-[#FBF9FE]',
  },
];

export const faqAccordion = [
  {
    id: 1,
    question: 'What is a virtual card?',
    answer:
      'A virtual card is a digital card that can be used for different kinds of international purchases.  You can pay for subscriptions, shop globally, and pay for international services. ',
  },
  {
    id: 2,
    question: 'Why should I use LaserCards over my physical card?',
    answer: 'It is safe and secure, prioritizes flexibility, and you can tailor your card to fit specific needs and spending goals.',
  },
  {
    id: 3,
    question: 'Is LaserCards safe to use?',
    answer: 'Click on Signup, correctly input your information, fund your card, and start transacting with LaserCards! ',
  },
  {
    id: 4,
    question: 'How do I create an account?',
    // eslint-disable-next-line quotes
    answer: "With LaserCards, you can save your phone's storage as there's no need for an additional application.",
  },
];

export const productFaqAccordion = [
  {
    id: 1,
    question: 'Can I customize card names or labels for better organization?',
    answer: 'Yes, you can customize card names or labels, making it easier to identify the purpose of each card and stay organized.',
  },
  {
    id: 2,
    question: 'How do I apply for LaserCards Pro Or Business?',
    answer: 'Applying is easy! Simply create an account and follow the signup process',
  },
  {
    id: 3,
    question: 'What happens if I reach the spending limit on a card?',
    answer:
      'If you reach the spending limit on a card, the card will be declined for further transactions until the limit is adjusted. You can modify the limit to accommodate your needs or allocate funds from other cards.',
  },
  {
    id: 4,
    question: 'Can I use LaserCards for international business travel expenses?',
    answer: 'Absolutely! LaserCards Pro and Business are versatile and can be used for both domestic and international travels.',
  },
];

export const supportFaqAccordion = [
  {
    id: 1,
    question: 'Why was my transaction declined?',
    answer:
      'This can be due to insufficient funds, incorrect details, spending limits, suspicious activity, blocked merchants, expired cards, or network glitches. ',
  },
  {
    id: 2,
    question: 'How can I reset my password?',
    answer:
      'To reset your password, click on the "Forgot Password" link on the login page, follow the instructions provided, and regain access to your account. ',
  },
  {
    id: 3,
    question: 'How can I fund my card?',
    answer: 'Simply make a transfer to the dedicated account number on your dashboard to fund your card.',
  },
  {
    id: 4,
    question: 'How can I track my transactions?',
    answer: 'When you log in, go to "Transaction History," and review all your card activities in one place.',
  },
];

export const testimonialCardLeft = [
  {
    id: 1,
    content:
      'Virtual transactions have been a hassle, but I really have never seen speed and seamless transactions with any other service.',
    image: '/assets/client1.svg',
    name: 'Adunoluwa Adeyemi',
  },
  {
    id: 2,
    content:
      'Virtual transactions have been a hassle, but I really have never seen speed and seamless transactions with any other service.',
    image: '/assets/client2.svg',
    name: 'Karen Ibeh',
  },
  {
    id: 3,
    content:
      'Virtual transactions have been a hassle, but I really have never seen speed and seamless transactions with any other service.',
    image: '/assets/client3.svg',
    name: 'Promise Ejiofor',
  },
  {
    id: 4,
    content:
      'Virtual transactions have been a hassle, but I really have never seen speed and seamless transactions with any other service.',
    image: '/assets/client4.svg',
    name: 'Usman Yakubu',
  },
];
export const testimonialCardRight = [
  {
    id: 1,
    content:
      'Virtual transactions have been a hassle, but I really have never seen speed and seamless transactions with any other service.',
    image: '/assets/client5.svg',
    name: 'Usman Yakubu',
  },
  {
    id: 2,
    content:
      'Virtual transactions have been a hassle, but I really have never seen speed and seamless transactions with any other service.',
    image: '/assets/client6.svg',
    name: 'Joke Ojo',
  },
  {
    id: 3,
    content:
      'Virtual transactions have been a hassle, but I really have never seen speed and seamless transactions with any other service.',
    image: '/assets/client7.svg',
    name: 'Benita Ubah',
  },
  {
    id: 4,
    content:
      'Virtual transactions have been a hassle, but I really have never seen speed and seamless transactions with any other service.',
    image: '/assets/client8.svg',
    name: 'Tope Orodeji',
  },
];

export const plansCard = [
  {
    id: 1,
    title: 'Basic',
    titleColor: 'text-primary',
    textColor: 'text-black',
    price: '$0',
    duration: 'for life',
    bgColor: 'bg-white-02',
    buttonTitle: 'Sign Up for Free',
    buttonColor: 'bg-primary',
    buttonText: 'text-white',
    details: [
      {
        idx: 1,
        textColor: 'text-black',
        detailsContent: '$1 card creation fee',
        image: '/assets/check-circle.svg',
      },
      {
        idx: 2,
        textColor: 'text-black',
        detailsContent: 'Enabled card withdrawals',
        image: '/assets/check-circle.svg',
      },
      {
        idx: 3,
        textColor: 'text-black',
        detailsContent: 'Ability to pause and terminate cards',
        image: '/assets/check-circle.svg',
      },
      {
        idx: 4,
        textColor: 'text-black',
        detailsContent: 'Access to browser extensions',
        image: '/assets/check-circle.svg',
      },
      {
        idx: 5,
        textColor: 'text-black',
        detailsContent: 'Spending restriction capped at $5k per month',
        image: '/assets/check-circle.svg',
      },
      {
        idx: 6,
        textColor: 'text-black',
        detailsContent: 'No Top-Up fee',
        image: '/assets/check-circle.svg',
      },
    ],
  },
  {
    id: 2,
    title: 'Pro',
    titleColor: 'text-white',
    textColor: 'text-white',
    price: '$20',
    duration: 'per month',
    bgColor: 'bg-primary',
    buttonTitle: 'Sign Up for Pro',
    buttonColor: 'bg-white',
    buttonText: 'text-primary',
    iconColor: 0,
    details: [
      {
        idx: 1,
        textColor: 'text-white',
        detailsContent: 'Access to 25 cards',
        image: '/assets/check-circle-white.svg',
      },
      {
        idx: 2,
        textColor: 'text-white',
        detailsContent: 'Enabled Card withdrawals',
        image: '/assets/check-circle-white.svg',
      },
      {
        idx: 3,
        textColor: 'text-white',
        detailsContent: 'Set spending restrictions',
        image: '/assets/check-circle-white.svg',
      },
      {
        idx: 4,
        textColor: 'text-white',
        detailsContent: 'Ability to pause and terminate cards',
        image: '/assets/check-circle-white.svg',
      },
      {
        idx: 5,
        textColor: 'text-white',
        detailsContent: 'Restrict card to a merchant category',
        image: '/assets/check-circle-white.svg',
      },
      {
        idx: 6,
        textColor: 'text-white',
        detailsContent: 'Group and organize your cards',
        image: '/assets/check-circle-white.svg',
      },
      {
        idx: 7,
        textColor: 'text-white',
        detailsContent: 'Customize cards',
        image: '/assets/check-circle-white.svg',
      },
      {
        idx: 8,
        textColor: 'text-white',
        detailsContent: 'Request physical cards',
        image: '/assets/check-circle-white.svg',
      },
      {
        idx: 9,
        textColor: 'text-white',
        detailsContent: 'Access to browser extensions',
        image: '/assets/check-circle-white.svg',
      },
      {
        idx: 10,
        textColor: 'text-white',
        detailsContent: 'No Top-Up fee',
        image: '/assets/check-circle-white.svg',
      },
    ],
  },
  {
    id: 3,
    title: 'Business',
    titleColor: 'text-green',
    textColor: 'text-black',
    price: '$30',
    duration: 'per month',
    bgColor: 'bg-white-03',
    buttonColor: 'bg-green',
    buttonTitle: 'Sign Up for Business',
    buttonText: 'text-white',
    details: [
      {
        idx: 1,
        textColor: 'text-black',
        detailsContent: 'Access to 40 cards',
        image: '/assets/check-circle-green.svg',
      },
      {
        idx: 2,
        textColor: 'text-black',
        detailsContent: 'Enabled Card withdrawals',
        image: '/assets/check-circle-green.svg',
      },
      {
        idx: 3,
        textColor: 'text-black',
        detailsContent: 'Set spending restrictions',
        image: '/assets/check-circle-green.svg',
      },
      {
        idx: 4,
        textColor: 'text-black',
        detailsContent: 'Pause and terminate cards',
        image: '/assets/check-circle-green.svg',
      },
      {
        idx: 5,
        textColor: 'text-black',
        detailsContent: 'Restrict card to a merchant category',
        image: '/assets/check-circle-green.svg',
      },
      {
        idx: 6,
        textColor: 'text-black',
        detailsContent: 'Group and organize your cards',
        image: '/assets/check-circle-green.svg',
      },
      {
        idx: 7,
        textColor: 'text-black',
        detailsContent: 'Customize card',
        image: '/assets/check-circle-green.svg',
      },
      {
        idx: 8,
        textColor: 'text-black',
        detailsContent: 'Request for physical cards',
        image: '/assets/check-circle-green.svg',
      },
      {
        idx: 9,
        textColor: 'text-black',
        detailsContent: 'Access to browser extensions.',
        image: '/assets/check-circle-green.svg',
      },
      {
        idx: 10,
        textColor: 'text-black',
        detailsContent: 'No Top-Up fee',
        image: '/assets/check-circle-green.svg',
      },
    ],
  },
];

export const signupAnimation = ['/assets/signupImage.svg', '/assets/signupImage1.svg', '/assets/signupImage2.svg'];

export const signupAccordionContent = [
  {
    id: 1,
    sn: '01',
    title: 'Basic Info',
    content: 'Tell us your name, email address, and create a secure password.',
  },
  {
    id: 2,
    sn: '02',
    title: 'Verify Your Identity',
    // eslint-disable-next-line quotes
    content: "Help us ensure your account's safety by verifying your identity. Your privacy is guaranteed.",
  },
  {
    id: 3,
    sn: '03',
    title: 'Fund And Create Card',
    content:
      'Pay the one-time card creation fee into your unique virtual card account number. Once that is done, you can fund your card and start shopping with ease.',
  },
];
