import React from 'react';
import Accordion from './Accordion';
import { faqAccordion } from '../../../constants';
import { Link } from 'react-router-dom';

const Faq = () => {
  return (
    <div className="relative max-w-[1440px] mx-auto flex flex-col lg:flex-row lg:justify-between pt-[68px] lg:pt-[89px] px-[18px] lg:px-[161px] pb-[69px]">
      <div>
        <h1 className="lg:w-[351px] text-headline-1 text-center lg:text-start">Frequently asked questions</h1>
      </div>
      <div className="lg:w-[551px] pt-[30px] lg:pt-0">
        <Accordion faqData={faqAccordion} />
        <p className="text-button pt-[20px]">
          Still have more questions?{' '}
          <span className="text-primary">
            <Link to="/contact">Contact Support</Link>
          </span>{' '}
          with all your questions. We’re ready to serve you.
        </p>
      </div>
    </div>
  );
};

export default Faq;
