import { Link } from 'react-router-dom';
import { Autopilot, Footer, Header } from '../../components';
import CustomButton from '../../components/CustomButton';
import Accordion from '../../components/LandingPage/FAQ/Accordion';
import { productFaqAccordion } from '../../constants';
import React from 'react';

export default function Products() {
  return (
    <div>
      <Header />
      <div className="bg-white">
        <div className="bg-graph bg-cover">
          <div className="flex flex-col justify-center items-center px-[18px] pt-[83px] lg:pt-[147px] pb-[48.38px] lg:pb-[130px]">
            <p className="text-secondary text-title text-center">VIRTUAL CARDS</p>
            <h1 className="lg:w-[485px] text-headline text-center pt-[19px]">Unlock More Possibilities With Our Virtual Cards</h1>
            <p className="lg:w-[339px] text-body text-dark-01 text-center pt-[21px]">
              Our virtual cards are designed to revolutionize your payment experience, providing you with a range of features that redefine
              how you make payments, ensuring convenience and security.
            </p>
            <div>
              <Link to="/auth">
                <CustomButton
                  title="Get Started for Free"
                  forwardIcon="/assets/forward.svg"
                  textStyles="text-white text-button"
                  containerStyles="bg-primary px-[47px] py-[16px] mt-[45px] rounded-full"
                />
              </Link>
            </div>
          </div>
        </div>
        <div className="bg-white-02">
          <Autopilot />
        </div>
        <div className="relative max-w-[1440px] mx-auto flex flex-col lg:flex-row lg:justify-between pt-[68px] lg:pt-[89px] px-[18px] lg:px-[161px] pb-[69px]">
          <div>
            <h1 className="lg:w-[351px] text-headline-1 text-center lg:text-start">Frequently asked questions</h1>
          </div>
          <div className="lg:w-[551px] pt-[30px] lg:pt-0">
            <Accordion faqData={productFaqAccordion} />
            <p className="text-button pt-[20px]">
              Still have more questions?{' '}
              <span className="text-primary">
                <Link to="/support">Contact Support</Link>
              </span>{' '}
              with all your questions. We’re ready to serve you.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
