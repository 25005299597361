import { Link } from 'react-router-dom';
import { menuLinks } from '../../constants';
import React from 'react';

const MenuItems = () => {
  return (
    <nav>
      <ul className="flex flex-col gap-4 md:flex md:flex-col lg:flex lg:flex-row lg:gap-[48px] pt-[11px]">
        {menuLinks.map(({ id, title, url }) => (
          <li key={id}>
            <Link to={url}>
              <p className="text-body-1 text-dark-01">{title}</p>
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default MenuItems;
