import React from 'react';
import { Footer, Header } from '../../components';

export default function Company() {
  return (
    <div>
      <Header />
      <div className="bg-white flex flex-col justify-center pb-[30px] lg:pb-[68px]">
        <div className="bg-graph bg-cover">
          <div className="flex flex-col justify-center items-center lg:flex-row px-[18px] pt-[83px] lg:pt-[147px] lg:pb-[48.38px]">
            <div>
              <p className="text-secondary text-title text-center lg:text-start uppercase">Our Team</p>
              <h1 className="lg:w-[485px] text-headline-1 text-center lg:text-start pt-[25px]">
                Building A World Of Innovation, Purpose, <br />
                And Excellence
              </h1>
            </div>
            <div>
              <p className="lg:w-[439px] text-body pt-[15px] lg:pt-[105px] text-center lg:text-start mt-auto">
                We are a team of seasoned professionals, dedicated to providing you with top-notch payment solutions. We constantly strive
                to deliver the best services while ensuring that you’re satisfied with our product
              </p>
            </div>
          </div>
          <div className="flex justify-center items-center pt-3 px-[18px]">
            <img src="/assets/companyImage.svg" alt="" width={936} height={409} />
          </div>
        </div>

        <div className="flex flex-col lg:flex-row gap-[16px] justify-center pt-[74px] px-[18px] lg:px-[161px]">
          <div className="bg-white-02 pt-[61px] pb-[24px] rounded-lg">
            <img src="/assets/missionImage.svg" alt="message icon" width={551} height={500} />

            <div className="pt-[18px] px-[20px] lg:px-[40px]">
              <h3 className="text-headline-3 pt-[3px]">Our Mission</h3>
              <p className="lg:w-[471px] text-body pt-[8px]">
                We cater to individuals and businesses, offering user-friendly solutions that help reshape how you handle transactions. Your
                satisfaction is our priority, and we are committed to providing exceptional services.
              </p>
            </div>
          </div>
          <div className="bg-card2 pt-[61px] pb-[24px] rounded-lg">
            <img src="/assets/visionImage.svg" alt="message icon" width={551} height={500} />

            <div className="pt-[18px] px-[20px] lg:px-[40px]">
              <h3 className="text-headline-3 pt-[3px]">Our Values</h3>
              <p className="lg:w-[471px] text-body pt-[8px]">
                Discover our unwavering dedication to quality, service, and transformative solutions. Join us as we work together to provide
                the best services, your support fuels our passion.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
