import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Home from './app/page';
import Support from './app/support/page';
import Company from './app/company/page';
import Plans from './app/plans/page';
import Products from './app/products/page';
import Auth from './app/auth/page';
import 'react-toastify/dist/ReactToastify.min.css';
import './app/globals.scss';

export const App = () => (
  <>
    <ToastContainer autoClose={3000} hideProgressBar={true} closeButton={<>✕</>} position="top-center" />

    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/auth" element={<Auth />} />
      <Route path="/support" element={<Support />} />
      <Route path="/company" element={<Company />} />
      <Route path="/plans" element={<Plans />} />
      <Route path="/products" element={<Products />} />
    </Routes>
  </>
);
