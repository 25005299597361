import React from 'react';
import CustomButton from '../CustomButton';
import { Link } from 'react-router-dom';

const MenuActions = () => {
  return (
    <div className="flex flex-col lg:flex-row gap-3 lg:gap-[18px]">
      <Link to="/auth">
        <CustomButton title="Login" btnType="button" containerStyles="lg:pt-[10px]" textStyles="text-body-1 text-dark-01" />
      </Link>
      <div className="">
        <Link to="/auth">
          <CustomButton
            title="Get Started"
            btnType="button"
            containerStyles="bg-primary pt-[10px] pb-[6px] px-[16px] rounded-full"
            textStyles="text-body-1 text-white"
            forwardIcon="/assets/forward.svg"
          />
        </Link>
      </div>
    </div>
  );
};

export default MenuActions;
