import React from 'react';
import { Link } from 'react-router-dom';
import { Faq, Features, Footer, Header, LaserCardsPlan, SignupProcess, Testimonials } from '../components';
import CustomButton from '../components/CustomButton';
import { useCase } from '../constants';
import Marquee from 'react-fast-marquee';

export default function Home() {
  return (
    <div>
      <div className="bg-white-02">
        <Header />
        <div className="bg-graph bg-cover">
          <img
            alt="hero"
            src="/assets/heroImage.svg"
            width={1200}
            height={1200}
            className="hidden 2xl:top-[8%] 2xl:left-[12%] lg:block absolute top-[13%] left-[5%]"
          />

          <div className="flex flex-col justify-center items-center pt-[83px] lg:pt-[147px]">
            <div className="flex flex-col justify-center items-center px-[18px] lg:px-[92px]">
              <p className="text-secondary text-title text-center uppercase">Streamline your payments conveniently</p>
              <h1 className="lg:w-[515px] text-headline text-center pt-[19px]">Simplifying your digital transactions</h1>
              <p className="md:w-[380px] text-body text-dark-01 text-center pt-[21px]">
                Our platform takes the complexity out of online purchases, ensuring convenience and security.
              </p>
              <div className="lg:pt-[45px] 2xl:pt-[25px] z-20 relative z-[5]">
                <Link to="/auth">
                  <CustomButton
                    title="Get Started for Free"
                    forwardIcon="/assets/forward.svg"
                    textStyles="text-white text-button"
                    containerStyles="cursor-pointer relative bg-primary z-5 px-[47px] py-[16px] mt-[45px] rounded-full z-[5]"
                  />
                </Link>
              </div>
            </div>
            <p className="text-dark-01 text-body-3 pt-[35px] lg:pt-[116px]">WE WORK PERFECTLY</p>
            <div className="lg:w-[810px]">
              <Marquee direction="left" speed={20} delay={5}>
                <div className="flex gap-[30px] mt-[52px] mr-[30px]">
                  {useCase.map(({ id, image, title }) => (
                    <div key={id} className={`${id === 7 ? 'h-[15px] w-[19px]' : ''}`}>
                      <img src={image} alt={title} width={100} height={28} className="grayscale pb-[45px]" />
                    </div>
                  ))}
                </div>
              </Marquee>
            </div>
          </div>
        </div>
        <div className="bg-white">
          <Features />
        </div>
        <div className="bg-white-02">
          <LaserCardsPlan />
        </div>
        <div className="bg-white">
          <SignupProcess />
        </div>
        <div className="bg-white hidden">
          <Testimonials />
        </div>
        <div className="bg-white-02">
          <Faq />
        </div>
      </div>
      <Footer />
    </div>
  );
}
