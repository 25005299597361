import { CustomButtonProps } from '../types';
import React from 'react';

const CustomButton = ({ title, containerStyles, handleClick, btnType, textStyles, forwardIcon }: CustomButtonProps) => {
  return (
    <button disabled={false} type={btnType || 'button'} className={`${containerStyles}`} onClick={handleClick}>
      <div className="flex gap-[7px]">
        <div className={`flex-1 ${textStyles}`}>{title}</div>
        {forwardIcon && (
          <div className={`relative w-6 h-6`}>
            <img src={forwardIcon} alt="button icon" />
          </div>
        )}
      </div>
    </button>
  );
};

export default CustomButton;
