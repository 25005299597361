import React from 'react';

const Autopilot = () => {
  return (
    <div className="px-[18px] lg:px-[161px]">
      <div className="flex flex-col lg:flex-row gap-[16px] lg:gap-[58px] 2xl:gap-[128px] pt-[80px]">
        <div>
          <h1 className="lg:w-[529px] text-headline-1 text-center lg:text-start">Redefine Convenience And Security With Our Features.</h1>
        </div>
        <div className="flex justify-center items-center">
          <p className="lg:w-[435px] text-body text-center mt-auto lg:text-start">
            Lasercards provides you with tools to fuel your business growth and manage your expenses seamlessly.
          </p>
        </div>
      </div>
      <div className="flex flex-col lg:flex-row gap-[16px] lg:justify-between pt-[45px] lg:pt-[92px] pb-[56px] lg:pb-[113px]">
        <div className={`bg-white px-[20px] lg:px-[40px] pt-[26px] pb-[40px] rounded-2xl`}>
          <img src="/assets/fee.svg" alt="" width={551} height={340} className="" />
          <h3 className="text-title-1 text-black pt-[17px]">Real-Time Notifications</h3>
          <p className="lg:w-[431px] pt-[3px] text-body text-dark-01">
            Stay informed with instant notifications for transactions, enhancing security and transparency.
          </p>
        </div>
        <div className={`bg-card2 px-[20px] lg:px-[40px] pt-[26px] pb-[40px] rounded-2xl`}>
          <img src="/assets/lowestRate.svg" alt="" width={551} height={340} className="" />
          <h3 className="text-title-1 text-black pt-[17px]">Global Accessibility</h3>
          <p className="lg:w-[431px] pt-[3px] text-body text-dark-01">
            Embrace the freedom of using your virtual cards internationally, expanding your purchasing power beyond borders.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Autopilot;
