import React from 'react';
import { Link } from 'react-router-dom';

const Navbar = () => {
  return (
    <div className="w-full">
      <div className="bg-white fixed w-full px-[18px] lg:px-[80px] py-[8px] flex justify-center lg:justify-start z-20 border-b">
        <Link to="/">
          <img src="/assets/newlogo.png" width={115} height={20} alt="lasercard logo" className="pt-[8px]" />
        </Link>
      </div>
    </div>
  );
};

export default Navbar;
