import React from 'react';

const Features = () => {
  return (
    <div className=" flex flex-col justify-center items-center px-[18px] lg:px-[161px] pt-[69px] pb-[95px]">
      <h4 className="text-secondary text-title">FEATURES</h4>
      <h2 className="lg:w-[529px] text-headline-1 text-center pt-[9px]">Endless Payment Possibilities</h2>
      <p className="lg:w-[435px] text-body text-center text-dark-01 pt-[16px]">
        Experience boundless payment options with our cutting-edge solution. Customize, track, and manage virtual cards for all your
        spending needs.
      </p>
      <div className="bg-#FFF8F5 hidden">
        <p className="text-#F1F9F4">hidden</p>
        <p className="text-[#FBF9FE]">hidden</p>
      </div>
      <div className="bg-[] grid grid-cols-1 md:grid-cols-2 gap-[16px] mt-[48px]">
        <div className={`bg-white-02 px-[20px] lg:px-[40px] pt-[26px] pb-[40px] rounded-2xl`}>
          <img src="/assets/fee.svg" alt="" width={551} height={340} className="" />
          <h3 className="text-title-1 text-black pt-[17px]">Instant Issuance</h3>
          <p className="lg:w-[431px] pt-[3px] text-body text-dark-01">
            Get your virtual card details instantly upon approval and start using it immediately
          </p>
        </div>
        <div className={`bg-card2 px-[20px] lg:px-[40px] pt-[26px] pb-[40px] rounded-2xl`}>
          <img src="/assets/lowestRate.svg" alt="" width={551} height={340} className="" />
          <h3 className="text-title-1 text-black pt-[17px]">Low Transaction Rates</h3>
          <p className="lg:w-[431px] pt-[3px] text-body text-dark-01">
            At LaserCards, we provide you with competitive transaction rates, helping you spend more at lower prices
          </p>
        </div>
        <div className={`bg-white-03 px-[20px] lg:px-[40px] pt-[26px] pb-[40px] rounded-2xl`}>
          <img src="/assets/fast.svg" alt="" width={551} height={340} className="" />
          <h3 className="text-title-1 text-black pt-[17px]">Fast And Easy</h3>
          <p className="lg:w-[431px] pt-[3px] text-body text-dark-01">
            No long waits, and no complicated forms. Experience quick and easy account verification and start shopping
          </p>
        </div>
        <div className={`bg-white-02 px-[20px] lg:px-[40px] pt-[26px] pb-[40px] rounded-2xl`}>
          <img
            src="/assets/inone.svg"
            alt=""
            width={551}
            height={340}
            className="md:mt-[80px] md:mb-[80px] lg:mt-[100px] lg:mb-[100px] 2xl:mt-[120px] 2xl:mb-[120px]"
          />
          <h3 className="text-title-1 text-black pt-[17px]">Subscriptions</h3>
          <p className="lg:w-[431px] pt-[3px] text-body text-dark-01">
            Manage your subscriptions automatically without exceeding your spending limit
          </p>
        </div>
      </div>
    </div>
  );
};

export default Features;
