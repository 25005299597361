import React from 'react';
import { Link } from 'react-router-dom';
import { Footer, Header } from '../../components';
import CustomButton from '../../components/CustomButton';
import { plansCard } from '../../constants';

export default function Plans() {
  return (
    <div>
      <Header />
      <div className="bg-white">
        <div className="bg-graph bg-cover">
          <div className="flex flex-col justify-center items-center px-[18px] lg:px-[92px] pt-[83px] lg:pt-[147px] pb-[48.38px] lg:pb-[63px]">
            <p className="text-secondary text-title text-center">PLANS & PRICING</p>
            <h1 className="lg:w-[474px] text-headline text-center pt-[19px]">Flexible Plans, Affordable Prices.</h1>
            <p className="lg:w-[420px] text-body text-dark-01 text-center pt-[21px]">
              Explore our range of plans, each designed to offer unique features and benefits. From basic essentials to comprehensive
              packages, we have a plan that fits your goals and budget.
            </p>
          </div>

          <div className="hidden bg-white-03">
            <p className="text-green">w</p>
          </div>

          <div className="flex justify-center items-center px-[18px] lg:px-[92px] pb-[80px] lg:pb-[169px]">
            <div className="bg-[] flex flex-col lg:flex-row gap-[24px]">
              {plansCard.map(
                ({ id, title, price, duration, details, bgColor, titleColor, buttonColor, buttonText, textColor, buttonTitle }) => (
                  <div key={id} className={`${bgColor} px-[29px] py-[33px] rounded-xl flex flex-col`}>
                    <div className="flex-start lg:h-[427px]">
                      <p className={`${titleColor}`}>{title}</p>
                      <div className="flex gap-[6px]">
                        <div>
                          <p className={`text-headline-1 ${textColor}`}>{price}</p>
                        </div>
                        <div>
                          <p className={`text-body pt-[24px] ${textColor}`}>{duration}</p>
                        </div>
                      </div>
                      {details.map(({ idx, detailsContent, image, textColor }) => (
                        <div key={idx} className="flex gap-[6px] pt-[16px]">
                          <img src={image} alt="check-icon" width={20} height={10} />

                          <div className="">
                            <p className={`lg:w-[260px] text-body ${textColor}`}>{detailsContent}</p>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="pt-[52px] mx-auto">
                      <Link to="/auth">
                        <CustomButton
                          title={`${buttonTitle}`}
                          forwardIcon="/assets/forward.svg"
                          textStyles={`${buttonText} text-button text-center`}
                          containerStyles={`${buttonColor} px-[47px] py-[16px] mt-[45px] rounded-full`}
                          iconColor="red"
                        />
                      </Link>
                    </div>
                  </div>
                ),
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
