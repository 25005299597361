import { Navbar } from '../../components';
import CustomButton from '../../components/CustomButton';
import React, { useState } from 'react';
import serializeForm from 'form-serialize';
import { toast } from 'react-toastify';
import { handleRequestErrors } from '../../utils';
import { addNewsletterSubscription } from '../../api/notifications';

export default function Auth() {
  const [loading, setLoading] = useState(false);

  const handleSubmit = (ev: React.FormEvent) => {
    ev.preventDefault();

    const form = ev.target as any;
    const { email } = serializeForm(form, { hash: true });
    setLoading(true);
    addNewsletterSubscription(email)
      .then(() => {
        // eslint-disable-next-line quotes
        toast.success("You've been successfully subscribed to our newsletter!");
        form.reset();
      })
      .catch((err) => handleRequestErrors(err))
      .finally(() => setLoading(false));
  };
  return (
    <div>
      <Navbar />
      <div className="flex flex-col lg:flex-row h-screen w-full pt-[80px] lg:pt-0">
        <div className="bg-white bg-cover flex-1 lg:w-[50%] flex flex-col px-[18px] lg:px-[81px]">
          <div className="my-auto pt-[100px] lg:pt-10 pb-[60px] lg:pb-0">
            <h1 className="text-headline-1 text-center lg:text-start">Basic Account</h1>
            <p className="md:w-[373px] text-body pt-[16px] text-center lg:text-start lg:mx-0 md:mx-auto">
              Already have an account or want to get started with <span className="text-body-0">LaserCards Basic</span>? Get started to
              enjoy all our offerings
            </p>
            <div className=" mt-[35px] mb-[50px] lg:mb-0 flex justify-center lg:justify-start">
              <a href={process.env.REACT_APP_BASIC_APP_URL}>
                <CustomButton
                  title="Continue"
                  forwardIcon="/assets/forward.svg"
                  textStyles="text-white text-button"
                  containerStyles="bg-primary px-[47px] py-[16px] rounded-full"
                />
              </a>
            </div>
          </div>
        </div>

        <div className="bg-card2 bg-cover flex-1 lg:w-[50%] flex flex-col px-[18px] lg:px-[81px] 2xl:px-[140px]">
          <div className="lg:my-auto pt-[100px] lg:pt-0 pb-[100px] lg:pb-0">
            <h4 className="text-title text-secondary uppercase text-center lg:text-start">Coming Soon</h4>
            <h1 className="text-headline-1 pt-[10px] text-center lg:text-start">Pro & Business</h1>
            <p className="md:w-[373px] text-body pt-[16px] text-center lg:text-start lg:mx-0 md:mx-auto">
              We’re launching really soon. Sign up to be the first to get notified once we launch.{' '}
            </p>
            <div className="flex justify-center lg:justify-start">
              <form
                onSubmit={handleSubmit}
                className="w-[340px] bg-white flex justify-between border border-white-01 rounded-full px-[16px] py-[8px] mt-[23px]"
              >
                <input
                  required
                  type="email"
                  placeholder="Your Email Address"
                  className="bg-transparent outline-0 w-[280px]"
                  name="email"
                  minLength={5}
                  maxLength={80}
                />
                <button type="submit">
                  {loading ? (
                    <div className="bg-primary rounded-full p-1 flex justify-center items-center">
                      <div className="w-5 h-5 border-t border-b rounded-full border-white animate-spin"></div>
                    </div>
                  ) : (
                    <img src="/assets/forward.svg" alt="arrow icon" width={35} height={35} className="bg-primary p-2 rounded-full" />
                  )}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
