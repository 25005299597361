import { signupAccordionContent } from '../../../constants';
import React, { useState } from 'react';
import { BiChevronDown, BiChevronUp } from 'react-icons/bi';

const SignupAccordion = () => {
  const [clicked, setClicked] = useState<number | null>(null);
  const [selectedId, setSelectedId] = useState<number | null>(null);

  const handleClick = (id: number) => {
    setSelectedId(id);
  };

  const toggle = (id: number) => {
    if (clicked === id) {
      return setClicked(null);
    }

    setClicked(id);
  };

  return (
    <div className="flex flex-col">
      {signupAccordionContent.map(({ id, title, content, sn }) => (
        <div className={`${id === 3 ? 'border-0' : 'border-b'}`} key={id} id={`item-${id}`}>
          <div key={id} className="flex gap-[4px] py-[20px] cursor-pointer" onClick={() => toggle(id)}>
            <div className={`text-title pt-[8px]`}>
              <p
                style={{
                  color: selectedId === id ? '#FF4800' : 'black',
                  cursor: 'pointer',
                }}
                onClick={() => handleClick(id)}
              >
                {sn}.
              </p>
            </div>
            <div className="w-[360px] lg:w-[400px]  flex justify-between">
              <span
                className={`text-start text-headline-3`}
                style={{
                  color: selectedId === id ? 'black' : '#AFAFAF',
                  cursor: 'pointer',
                }}
                onClick={() => handleClick(id)}
              >
                {title}
              </span>
              <span
                style={{
                  color: selectedId === id ? 'black' : '#AFAFAF',
                  cursor: 'pointer',
                }}
                className="pt-[8px]"
              >
                {clicked === id ? <BiChevronUp /> : <BiChevronDown />}
              </span>
            </div>
          </div>
          {clicked === id ? <p className={`text-start text-body lg:w-[335px]`}>{content}</p> : null}
        </div>
      ))}
    </div>
  );
};

export default SignupAccordion;
