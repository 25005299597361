import React, { useState } from 'react';
import MenuItems from './MenuItems';
import MenuActions from './MenuActions';
import { HiBars3BottomRight } from 'react-icons/hi2';
import { Link } from 'react-router-dom';

const Header = () => {
  const [open, setOpen] = useState<any | null>(null);

  return (
    <div className="relative mx-auto">
      {/* mobile screen */}
      <div
        className={`${
          open ? '-translate-x-0' : '-translate-y-full'
        } fixed w-full bg-white p-7 transition-all duration-300 ease-in-out filter z-10 lg:hidden`}
      >
        <div className="relatve text-center lg:hidden" onClick={() => setOpen(!open)}>
          <div className="pt-8 pb-3">
            <MenuItems />
          </div>
          <MenuActions />
        </div>
      </div>

      {/* larger screen */}
      <div className="bg-white fixed w-full px-[18px] lg:px-[80px] py-[8px] flex justify-between z-20 border-b ">
        <Link to="/">
          <img src="/assets/newlogo.png" width={115} height={20} alt="lasercard logo" className="pt-[8px]" />
        </Link>
        <HiBars3BottomRight onClick={() => setOpen(!open)} className="lg:hidden text-3xl text-primary cursor-pointer" />
        <div className="hidden lg:flex">
          <MenuItems />
        </div>
        <div className="hidden lg:flex">
          <MenuActions />
        </div>
      </div>
    </div>
  );
};

export default Header;
